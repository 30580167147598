<template>
  <div class="space-y-3">
    <UInput
      v-if="showFilter"
      id="hub-tree-search"
      v-model="filterValue"
      name="hub-tree-search"
      size="sm"
      :placeholder="$t(searchPlaceholderLocalisationKey)"
      class="w-full"
      :disabled="!hasNodes"
      :tabindex="tabIndex"
      :ui="{
        rounded: 'md',
        icon: { trailing: { pointer: '' } },
      }"
      @update:model-value="(e: string | undefined) => { loading = true; filterNodes(e) }"
    >
      <template #trailing>
        <UIcon
          v-if="loading"
          name="i-mdi-loading"
          class="animate-spin"
        />
        <UButton
          v-else-if="filterValue && filterValue !== ''"
          color="gray"
          variant="link"
          icon="i-mdi-close"
          :padded="false"
          @click="loading = true; filterNodes(undefined)"
        />
        <UIcon
          v-else
          name="i-mdi-magnify"
        />
      </template>
    </UInput>

    <Tree
      v-bind="$attrs"
      v-model:expandedKeys="expandedKeys"
      :selection-keys="internalSelectionKeys"
      class="w-full"
      selection-mode="checkbox"
      :value="filteredNodes"
      :pt="treeStyle"
      unstyled
      @node-expand="onNodeExpand"
      @update:selection-keys="updateSelectionKeys"
    >
      <template #togglericon="{ node, expanded }">
        <UIcon
          v-if="node.icon"
          :name="node.icon"
          class="!size-5 text-grey-blue hover:text-super-dark-grey"
        />
        <UIcon
          v-else-if="node.children && node.children.length > 0"
          :name="expanded ? 'i-mdi-chevron-down-circle-outline' : 'i-mdi-chevron-right-circle-outline'"
          class="!size-6 text-inherit"
        />
      </template>

      <template #checkboxicon="{ checked, partialChecked }">
        <UIcon
          v-if="checked"
          name="i-mdi-check"
        />
        <UIcon
          v-if="!checked && partialChecked"
          name="i-mdi-minus"
        />
      </template>

      <template #default="slotProps">
        <slot
          name="item"
          :item="slotProps.node"
        >
          {{ slotProps.node.label }}
        </slot>
      </template>
    </Tree>
  </div>
</template>

<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import Tree, { type TreeExpandedKeys, type TreeSelectionKeys } from 'primevue/tree'
import type { TreeNode } from 'primevue/treenode'
import type { TreeNodeWithKey } from '~/types/tree'
import { validateSelectedKeys } from '~/utils/tree-helpers'

// props
const props = withDefaults(
  defineProps<{
    value: TreeNodeWithKey[]
    selectionKeys: TreeSelectionKeys | undefined
    showFilter?: boolean
    searchPlaceholderLocalisationKey?: string
    hideToggleIcon?: boolean
  }>(),
  {
    searchPlaceholderLocalisationKey: 'search',
    showFilter: true,
    hideToggleIcon: false
  }
)

// emits
const emit = defineEmits<{
  (e: 'update:selectionKeys', value: TreeSelectionKeys | undefined): void
}>()

const viewport = useViewport()

// internal refs
const tabIndex: Ref<number> = ref(0)
const loading: Ref<boolean> = ref(false)
const filterValue: Ref<string | undefined> = ref(undefined)
const expandedKeys: Ref<TreeExpandedKeys> = ref({})
const internalSelectionKeys: Ref<TreeSelectionKeys | undefined> = ref(props.selectionKeys)

// computed props
const hasNodes = computed(() => props.value.length > 0)
const filteredNodes = computed(() => {
  if (!filterValue.value) return props.value

  const lowerCaseFilterValue = filterValue.value.toLowerCase()
  const clonedProps = cloneDeep(props.value)
  const filteredNodes = filterChildren(clonedProps, lowerCaseFilterValue)

  return filteredNodes
})

// watchers
watch(
  () => viewport.isLessThan('md'),
  isMobile => {
    // Note to self: this is to control what is focused when the tree is rendered
    if (!isMobile) {
      tabIndex.value = 0
    } else {
      tabIndex.value = -1
    }
  },
  {
    immediate: true
  }
)

watch(
  () => props.selectionKeys,
  newValues => {
    internalSelectionKeys.value = newValues
  }
)

// functions
const updateSelectionKeys = (event: TreeSelectionKeys) => {
  let newSelectionKeys = event
  if (filterValue.value && internalSelectionKeys.value && !objectIsEmpty(internalSelectionKeys.value)) {
    newSelectionKeys = { ...internalSelectionKeys.value, ...event }
  }

  internalSelectionKeys.value = validateSelectedKeys(newSelectionKeys, props.value)
  emit('update:selectionKeys', internalSelectionKeys.value)
}

function filterChildren(children: Array<TreeNodeWithKey> | undefined, filterValue: string | undefined):
Array<TreeNodeWithKey> {
  // filterValue should always be a lowercase string based on where we're calling this function
  if (!children) return []
  if (!filterValue) return children

  return children.reduce((acc, node) => {
    if (node.label.toLowerCase().includes(filterValue)) {
      acc.push(node)
    }

    if (node.children) {
      node.children = filterChildren(node.children, filterValue)
      if (node.children.length > 0 && !acc.some(n => n.key === node.key)) {
        acc.push(node)
      }
    }

    return acc
  }, [] as Array<TreeNodeWithKey>)
}

const onNodeExpand = (node: TreeNode) => {
  expandNode(node, false)
}

const expandAll = () => {
  for (const node of filteredNodes.value) {
    expandNode(node)
  }
}

const expandNode = (node: TreeNode, expandChildren: boolean = true) => {
  if (node.children && node.children.length && node.key) {
    expandedKeys.value[node.key] = true

    if (expandChildren) {
      for (const child of node.children) {
        expandNode(child)
      }
    }
  }
}

const filterNodes = debounce((value: string | undefined) => {
  filterValue.value = value
  if (value && value?.trim().length > 1) {
    // Expand all nodes when a new value is entered in the text field
    expandAll()
    // Collapse all nodes when value is erased
  } else if (!value || value?.trim().length === 0) {
    expandedKeys.value = {}
  }
  loading.value = false
}, 250)

// style
const treeStyle = {
  root: {
    class: ['relative p-0 rounded-md text-sm text-grey-blue ']
  },
  wrapper: {
    class: ['overflow-auto']
  },
  container: {
    class: ['m-0 p-0', 'list-none overflow-auto']
  },
  node: {
    class: ['p-1 focus:ring-trublue-400/50 dark:focus:ring-trublue-300/50 rounded-md']
  },
  // @ts-expect-error - this is fine
  content: ({ context, props }) => ({
    class: [
      // Flex and Alignment
      'flex items-center',
      // Shape
      'rounded-md',
      // Spacing
      'p-1',
      // Colors
      'text-surface-700 dark:text-surface-0',
      { 'bg-trublue-50 dark:bg-trublue-400/30 text-trublue dark:text-surface-0': context.selected },
      // States
      {
        'hover:bg-mid-grey dark:hover:bg-surface-400/10':
          props.selectionMode === 'single' || props.selectionMode === 'multiple'
      },
      // Transition
      'transition-shadow duration-200',
      { 'cursor-pointer select-none': props.selectionMode === 'single' || props.selectionMode === 'multiple' }
    ]
  }),
  toggler: () => ({
    class: [
      props.hideToggleIcon ? 'hidden' : '',
      // Flex and Alignment
      'inline-flex items-center justify-center',
      // Shape
      'border-0 rounded-lg',
      // Size and Spacing
      'mr-2',
      'size-6',
      // Colors
      'text-trublue',
      'bg-transparent',
      // States
      'hover:text-blue-3 dark:hover:text-white/80',
      // Transition
      'transition duration-200',
      // Misc
      'cursor-pointer select-none'
    ]
  }),
  nodeCheckbox: {
    root: {
      class: ['relative', 'inline-flex', 'align-bottom', ' size-4 mr-3', 'cursor-default', 'select-none']
    },
    // @ts-expect-error - this is fine
    box: ({ props, context }) => ({
      class: [
        // Alignment
        'flex items-center justify-center',
        // Size
        'size-4',
        // Shape
        'rounded border',
        // Border
        'border-grey-blue',
        {
          'bg-surface-0 dark:bg-surface-900 text-grey-blue': !context.checked,
          'bg-trublue dark:bg-trublue-400 text-white': context.checked
        },
        {
          'ring-2 ring-trublue dark:ring-primary-400': !props.disabled && context.focused,
          'cursor-default opacity-60': props.disabled
        },
        // States
        {
          'peer-focus-visible:ring-2 peer-focus-visible:ring-trublue dark:peer-focus-visible:ring-trublue-400':
            !props.disabled,
          'cursor-default opacity-60': props.disabled
        },
        // Transitions
        'transition-colors',
        'duration-200'
      ]
    }),
    input: {
      class: [
        'peer',
        'w-full ',
        'h-full',
        'absolute',
        'top-0 left-0',
        'z-10',
        'p-0',
        'm-0',
        'rounded',
        'border',
        'opacity-0',
        'rounded-md',
        'outline-none',
        'border-super-dark-grey dark:border-surface-700',
        'appearance-none',
        'cursor-pointer'
      ]
    },
    icon: {
      class: ['text-normal', 'size-2.5', 'text-white dark:text-surface-900', 'transition-all', 'duration-200']
    }
  },
  nodeicon: {
    class: ['mr-2', 'text-surface-600 dark:text-white/70 hidden']
  },
  subgroup: {
    class: ['m-0 list-none p-0 pl-3.5 mt-1']
  },
  loadingicon: {
    class: ['text-grey-blue dark:text-surface-0/70', 'absolute top-[50%] right-[50%] -mt-2 -mr-2 animate-spin']
  }
}
</script>

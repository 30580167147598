<template>
  <HubModal
    id="dashboard-outlet-filter-modal"
    :title="$t('organisations.outlet', 2)"
    icon="i-mdi-file-tree-outline"
    confirm-text="filters.applyFilters"
    :translate-title="false"
    :content-padding="false"
    @confirm="applyFilter"
    @cancel="cancelFilter"
  >
    <div>
      <div class="my-4 justify-between px-6 md:flex">
        <div class="overflow-x-visible overflow-y-scroll px-4 py-2 md:w-3/5 lg:max-h-[75vh]">
          <HubTabs
            v-if="countries.length > 1"
            v-model:active-index="activeTabIndex"
            :items="tabItems"
            class="mb-4"
            header-key="label"
            theme="blue"
          >
            <template #item="{ item }">
              <HubTree
                v-if="item.id === 'outlet'"
                v-model:selection-keys="selectedNodes"
                class="mt-4 w-full"
                :value="item.value"
                search-placeholder-localisation-key="filters.searchOutlets"
                @update:selection-keys="updateNodes"
              />
              <HubTree
                v-else
                v-model:selection-keys="selectedCountries"
                hide-toggle-icon
                :show-filter="false"
                class="mt-4 w-full"
                :value="item.value"
                @update:selection-keys="updateCountries"
              />
            </template>
          </HubTabs>
          <HubTree
            v-else
            v-model:selection-keys="selectedNodes"
            class="w-full"
            :value="nodes"
            search-placeholder-localisation-key="filters.searchOutlets"
          />
        </div>

        <div class="mt-4 max-h-96 min-h-full overflow-y-scroll rounded-lg bg-blue-7 md:mt-0 md:w-2/5 lg:max-h-[60vh]">
          <div class="h-full p-3">
            <div class="flex items-baseline justify-between">
              <div class="flex items-baseline justify-between text-sm font-semibold text-grey-blue">
                {{ totalSelectedOutletsCount }} {{ $t('filters.selected') }}
                <span
                  v-if="totalSelectedOutletsCount === 0"
                  class="ml-1"
                >({{ $t('filters.showAll') }})</span>
              </div>
              <HubButton
                v-if="totalSelectedOutletsCount > 0"
                icon="i-mdi-remove-bold"
                icon-classes="!size-3"
                type="outlined-transparent"
                size="xs"
                :label="$t('clear')"
                @click="deselectAllOutlets"
              />
            </div>
            <div class="mt-4 hidden md:block">
              <div
                v-for="outlet of selectedOrganisations"
                :key="outlet.key"
                class="items-center justify-between text-sm text-grey-blue md:flex"
              >
                <p>{{ outlet.node.label }}</p>
                <UIcon
                  name="i-mdi-remove-circle-outline"
                  class="my-1 !size-5 cursor-pointer text-trublue hover:text-blue-3"
                  @click="deselectOutlet(outlet.key)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </HubModal>
</template>

<script setup lang="ts">
import type { TreeSelectionKeys } from 'primevue/tree'
import type { HubTouchpointConfig } from '~/types/configuration'
import type { HubOrganisation } from '~/types/organisation'
import type { CollectionOfSelectedTreeNodes, TreeNodeWithKey } from '~/types/tree'
import { getSelectedKeysFromNodes, getSelectedNodesFromKeys } from '~/utils/tree-helpers'

const props = withDefaults(defineProps<{
  organisationId: number
  selectedOutlets?: Array<string>
  totalSelectedOutletsCount?: number
}>(), {
  totalSelectedOutletsCount: 0
})
const emit = defineEmits<{
  (e: 'update:total-selected-outlets-count', totalSelectedOutletsCount: number): void
  (e: 'update:selected-outlets', selectedOutlets: Array<string>): void
  (e: 'update:model-value', arg1: boolean): void
}>()
const organisationStore = useOrganisationStore()
const touchpointStore = useTouchpointStore()
const filterStore = useFilterStore()
const { params } = storeToRefs(filterStore)
const { currentOrganisationTouchpoints } = storeToRefs(touchpointStore)
const { organisation } = storeToRefs(organisationStore)
const { t, locale } = useI18n()

// local refs
const selectedNodes: Ref<CollectionOfSelectedTreeNodes | undefined> = ref()
const selectedCountries: Ref<CollectionOfSelectedTreeNodes | undefined> = ref()
const activeTabIndex = ref()

// computed refs
const appliedTouchpoints: ComputedRef<Array<HubTouchpointConfig | undefined>> = computed(() => {
  if (!params.value.touchpointIds) {
    return []
  }

  if (typeof params.value.touchpointIds === 'string') {
    const touchpoint = currentOrganisationTouchpoints.value.find(t => t.id === params.value.touchpointIds)
    if (touchpoint) {
      return [touchpoint]
    }

    return []
  }

  return (params.value.touchpointIds as Array<string>).map((id: string) =>
    currentOrganisationTouchpoints.value.find(t => t.id === id)
  )
})

const nodes: ComputedRef<Array<TreeNodeWithKey>> = computed(() => {
  if (!organisation.value) return []

  const sortNodes = (nodes: Array<TreeNodeWithKey>): Array<TreeNodeWithKey> => {
    return nodes.sort((a, b) => {
      if (a.data.item.touchpoint === b.data.item.touchpoint) {
        return a.label.localeCompare(b.label)
      } else {
        if (!a.data.item.touchpoint) {
          if (!a.data.item.shoppingChannel) {
            return -1
          }

          if (a.data.item.shoppingChannel.toLowerCase() === 'in-store') return -1
          if (a.data.item.shoppingChannel.toLowerCase() === 'online') return 1
          return 0
        }

        if (a.data.item.touchpoint.toLowerCase() === 'instore') return -1
        if (a.data.item.touchpoint.toLowerCase() === 'online') return 1
        return 0
      }
    })
  }

  const traverseAndSort = (node: TreeNodeWithKey): TreeNodeWithKey => {
    if (node.children) {
      node.children = sortNodes(node.children.map(child => traverseAndSort(child)))
    }
    return node
  }

  const touchpointQueryParamOptions = appliedTouchpoints.value.map(t => {
    if (t?.queryParams.touchpoint) {
      return (t.queryParams.touchpoint as string).toLowerCase()
    }
  })

  const filteredNodes = organisation.value.children.length
    ? (organisation.value.children
        .map(child => organisationToTreeNode(child))
        .filter(c => {
          if (!c || Array.isArray(c)) return false

          if (touchpointQueryParamOptions.length) {
            const filterBasedOnTouchpoint = (node: TreeNodeWithKey): boolean => {
              if (!node.data.item.touchpoint && !node.children) return true

              if (!node.data.item.touchpoint && node.children) {
                node.children = node.children.filter(child => filterBasedOnTouchpoint(child))

                return node.children.length > 0
              }

              return touchpointQueryParamOptions.includes(node.data.item.touchpoint.toLowerCase())
            }

            return filterBasedOnTouchpoint(c)
          }

          return c
        }) as Array<TreeNodeWithKey>)
    : [organisationToTreeNode(organisation.value) as TreeNodeWithKey]

  const sortedNodes = sortNodes(filteredNodes as TreeNodeWithKey[])
  return sortedNodes.map(node => traverseAndSort(node))
})

const countries: ComputedRef<Array<TreeNodeWithKey>> = computed(() => {
  const uniqueCountries = new Set<string>()

  const traverseChild = (node: TreeNodeWithKey) => {
    if (node.children) {
      node.children.forEach(child => traverseChild(child))
    }
    if (node.data.item.isOen_name) {
      uniqueCountries.add(node.data.item.isOen_name)
    }
  }

  nodes.value.forEach(node => traverseChild(node))

  return Array.from(uniqueCountries).map(country => ({
    key: country,
    label: country
  }))
})

const tabItems = computed(() => {
  const items = [
    { label: t('organisations.outlet', 2), id: 'outlet', value: nodes.value },
    { label: t('organisations.country', 2), id: 'country', value: countries.value }
  ]

  return items
})

const selectedOrganisations: ComputedRef<Array<{ key: string, node: TreeNodeWithKey, leaf: boolean }>> = computed(
  () => {
    if (!selectedNodes.value || !nodes.value) return []

    const selectedOutlets = getSelectedKeysFromNodes(selectedNodes.value)
    const nodeObjects = getSelectedNodeObjectsFromKeys(selectedOutlets, nodes.value)

    return nodeObjects.filter(n => n.leaf)
  }
)

// watchers
watch(
  () => props,
  () => {
    organisationStore.getOrganisation(
      props.organisationId,
      false,
      locale.value ? locale.value.split('-')[0] : undefined
    )
  },
  {
    deep: true,
    immediate: true
  }
)
watch(
  [() => props.selectedOutlets, () => nodes.value],
  () => {
    if (!props.selectedOutlets) {
      selectedNodes.value = {}
      return
    }
    selectedNodes.value = getSelectedNodesFromKeys(props.selectedOutlets, nodes.value)
  },
  {
    deep: true,
    immediate: true
  }
)

watch(
  [() => selectedOrganisations.value],
  () => emit('update:total-selected-outlets-count',
    (selectedOrganisations.value ? Object.keys(selectedOrganisations.value).length : 0)
  ),
  {
    immediate: true
  }
)

// functions
function getTouchpointIcon(touchpointQueryParam: string): string | undefined {
  const touchpoint = currentOrganisationTouchpoints.value.find(t =>
    t.queryParams.touchpoint.toLowerCase() === touchpointQueryParam.toLowerCase()
  )

  return touchpoint?.icon
}
function updateNodes(value: TreeSelectionKeys | undefined) {
  selectedNodes.value = value

  if (!selectedNodes.value) return
  if (!selectedCountries.value) return

  const selectedCountryKeys = getSelectedKeysFromNodes(selectedCountries.value)
  const selectedOutlets = getSelectedKeysFromNodes(selectedNodes.value)
  const outletsFlattened = getAllNodesFlattened(nodes.value)
  const newSelectedCountries = { ...selectedCountries.value }

  for (const countryKey of selectedCountryKeys) {
    const nodesForCountry = outletsFlattened.filter(node => node.node.data.item.isOen_name === countryKey).length
    const selectedNodesForCountry = outletsFlattened.filter(node => selectedOutlets.includes(node.key))
    const totalNodesForCountrySelected = selectedNodesForCountry.reduce((acc, node) => {
      const getChildrenCount = (node: TreeNodeWithKey): number => {
        if (!node.children) return 1
        return node.children.reduce((acc, child) => acc + getChildrenCount(child), 1)
      }

      return acc + getChildrenCount(node.node)
    }, 0)

    if (totalNodesForCountrySelected !== nodesForCountry) {
      newSelectedCountries[countryKey] = { checked: false, partialChecked: false }
    }
  }

  selectedCountries.value = newSelectedCountries
}

function updateCountries(value: TreeSelectionKeys | undefined) {
  selectedCountries.value = value

  if (!selectedCountries.value) return

  const selectedKeys = getSelectedKeysFromNodes(selectedCountries.value)
  selectedNodes.value = getSelectedNodesFromGivenParam('isOen_name', selectedKeys, nodes.value)
}
function organisationToTreeNode(org: HubOrganisation, previousKey: string = ''): TreeNodeWithKey | undefined {
  if (!org || !org.item) return

  if (!org.item.calculatedPermission.includes('viewAnalytics')) {
    if (org.item.hasChildPermission) {
      // If user is not authorised to view the node,
      // but has children that they are allowed to see, treat the children as the node

      const permissibleChildNodes = []
      for (const child of org.children) {
        const treeNode = organisationToTreeNode(child)
        if (treeNode) permissibleChildNodes.push(treeNode)
      }

      const flatPermissibleChildNodes = permissibleChildNodes.flat()
      return flatPermissibleChildNodes.length === 1 ? flatPermissibleChildNodes[0] : flatPermissibleChildNodes
    }

    // If user is not authorised to view the node or any of the children, return nothing
    return
  }

  const children: Array<TreeNodeWithKey> | undefined = org.children
    ? org.children.map(child => {
      const treeNode = organisationToTreeNode(child, previousKey + org.item.organisationNodeId.toString() + '-')
      return treeNode || undefined
    }).filter(child => child) as Array<TreeNodeWithKey>
    : undefined

  let icon: string | undefined = undefined
  if (org.item.touchpoint) {
    icon = getTouchpointIcon(org.item.touchpoint)
  }

  return {
    key: previousKey + org.item.organisationNodeId.toString(),
    label: org.item.organisationNodeName || 'Name Unknown',
    icon: !children?.length ? icon || 'i-mdi-feedback-outline' : undefined,
    data: org,
    children
  }
}
function deselectOutlet(outletKey: string) {
  if (!selectedNodes.value) return

  const newSelectedNodes = { ...selectedNodes.value }

  const splitKey = outletKey.split('-')
  const keyArray: Array<string> = []
  let newKey = ''
  for (const key of splitKey) {
    newKey = newKey + key
    keyArray.push(newKey)
    newKey = newKey + '-'
  }

  for (const key of keyArray) {
    newSelectedNodes[key] = { checked: false, partialChecked: true }
  }
  newSelectedNodes[outletKey] = { checked: false, partialChecked: false }

  const currentSelectedKeys = getSelectedKeysFromNodes(newSelectedNodes)
  selectedNodes.value = getSelectedNodesFromKeys(currentSelectedKeys, nodes.value)
  selectedCountries.value = {}
}
function deselectAllOutlets() {
  selectedNodes.value = {}
  selectedCountries.value = {}
}

function applyFilter() {
  emit('update:selected-outlets', selectedNodes.value ? getSelectedKeysFromNodes(selectedNodes.value) : [])
  emit('update:model-value', false)
}
function cancelFilter() {
  if (props.selectedOutlets) {
    selectedNodes.value = getSelectedNodesFromKeys(props.selectedOutlets, nodes.value)
  } else {
    selectedNodes.value = {}
    selectedCountries.value = {}
  }
  emit('update:model-value', false)
}
</script>
